import {
  AppBar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  LinearProgress,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import { useAppDispatch } from "../app/hooks";
import { callbackAsync } from "../features/system/systemSlice";
import { RootState } from "../app/store";
import { useSelector } from "react-redux";

const OAuth = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const code = urlParams.get("code");
  const state = urlParams.get("state");
  const errorQuery = urlParams.get("error");
  const dispatch = useAppDispatch();

  const [error, setError] = useState<string>(errorQuery!);
  const [errorDescription, setErrorDescription] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const callbackState = useSelector(
    (state: RootState) => state.system.callbackState
  );
  const callbackLoading = useSelector(
    (state: RootState) => state.system.callbackLoading
  );

  if (callbackState !== state) {
    dispatch(callbackAsync({ oauthState: state!, oauthCode: code! }));
  }

  return (
    <Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              MinIO Subscription Network
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={3}>
          <Card sx={{ width: 360 }}>
            <CardContent>
              <Typography variant="h5" component="div">
                Authenticated
              </Typography>
              <Typography variant="body2">
                {(error !== "" || errorDescription !== "") && (
                  <Fragment>
                    <div>{error}</div>
                    <div>{errorDescription}</div>
                  </Fragment>
                )}
              </Typography>
              <Typography variant="body2">
                You've been authenticated as a user
                {callbackLoading && <LinearProgress />}
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                Environment: {process.env.NODE_ENV}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant={"contained"}
                onClick={() => {
                  window.location.href = "/";
                }}
              >
                Back to login
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default OAuth;
