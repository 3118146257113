// A mock function to mimic making an async request for data
export function fetchCount(amount = 1) {
  return new Promise<{ data: number }>((resolve) =>
    setTimeout(() => resolve({ data: amount }), 500)
  );
}

export function fetchSessionStatus() {
  return new Promise<{ state: "valid" | "invalid" }>((resolve) =>
    fetch("/api/v1/authentication/session").then((res) => {
      if (res.status >= 300) {
        return Promise.reject();
      }
      resolve({ state: "valid" });
    })
  );
}

export function fetchCallback(code: string, state: string) {
  return new Promise<{ redirectUrl?: string }>((resolve) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ code, state }),
    };

    return fetch("/api/v1/authentication/callback", requestOptions)
      .then((res) => {
        if (res.status >= 300) {
          return Promise.reject();
        }
        return res.json();
      })
      .then(
        (result: { redirect_url: string }) => {
          resolve({ redirectUrl: result.redirect_url });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log("INVALID callback");
          resolve({});
        }
      );
  });
}
