import { createTheme } from "@mui/material";

const mainTheme = createTheme({
  palette: {
    primary: {
      main: "#081c42",
    },
  },
});

export default mainTheme;
