import React, { Fragment, useState } from "react";
import {
  Alert,
  AppBar,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { Counter } from "../features/system/Counter";
import { useAppSelector } from "../app/hooks";
import {
  selectCount,
  selectSessionLoaded,
  selectSessionStatus,
} from "../features/system/systemSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

const Dashboard = () => {
  const sessionStatus = useAppSelector(selectSessionStatus);
  const sessionLoaded = useAppSelector(selectSessionLoaded);
  const { instanceId } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = handleSubmit((data) => {
    console.log(data);
    submitForm();
  });

  console.log(errors);

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [role, setRole] = useState<string>("user");
  const [error, setError] = useState<string>("");

  // landing here check for parameters

  const navigate = useNavigate();

  if (sessionLoaded && sessionStatus == "loggedOut") {
    window.location.href = `/api/v1/authentication/login-start?instance_id=${encodeURIComponent(
      instanceId + ""
    )}`;
  }

  const submitForm = () => {
    setError("");
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        email: email,
        role: role,
        instance_id: instanceId,
      }),
    };

    return fetch(`/api/v1/users/`, requestOptions)
      .then((res) => {
        if (res.status >= 300) {
          return Promise.reject(res);
        }
        return res;
      })
      .then(
        (result) => {
          navigate(`/users/${instanceId}/`);
        },
        (error) => {
          error.json().then((body: { code: string; message: string }) => {
            setError(body.message);
          });
        }
      );
  };

  return (
    <Box paddingTop={7}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              MinIO Subscription Network
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <Grid
        container
        spacing={0}
        style={{ maxWidth: 1240, margin: "auto" }}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <h1>Add New User</h1>
            </Grid>
            <Grid item>
              <Button
                variant={"outlined"}
                onClick={() => {
                  navigate(`/users/${instanceId}/`);
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom>
            You can request to add as many users from your organization to your
            MinIO Subnet account.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <form noValidate autoComplete="off" onSubmit={onSubmit}>
            <Grid container spacing={2}>
              {error !== "" && (
                <Grid item xs={12}>
                  <Alert severity="error">{error}</Alert>
                </Grid>
              )}
              <Grid item xs={6}>
                <TextField
                  required
                  id="first_name"
                  label="First Name"
                  fullWidth
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  id="last_name"
                  label="Last Name"
                  fullWidth
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={9}>
                <TextField
                  required
                  id="email"
                  label="Email"
                  fullWidth
                  {...register("Email", {
                    required: true,
                    pattern: /^\S+@\S+$/i,
                  })}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  error={errors.Email ? true : false}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Select
                    labelId="role"
                    id="role"
                    label="Role"
                    value={role}
                    onChange={(e) => {
                      setRole(e.target.value);
                    }}
                  >
                    <MenuItem value={"user"}>User</MenuItem>
                    <MenuItem value={"admin"}>Admin</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} textAlign={"right"}>
                <Button variant={"contained"} type={"submit"}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
