import React from "react";
import "./App.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./pages/Main";
import OAuth from "./pages/OAuth";
import { ThemeProvider } from "@emotion/react";
import mainTheme from "./theme";
import { GlobalStyles } from "@mui/material";
import Dashboard from "./pages/Dashboard";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import {
  checkSessionAsync,
  selectSessionLoaded,
} from "./features/system/systemSlice";
import AddUser from "./pages/AddUser";
import Users from "./pages/Users";

const App = () => {
  const dispatch = useAppDispatch();
  const sessionLoaded = useAppSelector(selectSessionLoaded);

  if (!sessionLoaded) {
    dispatch(checkSessionAsync());
  }

  return (
    <ThemeProvider theme={mainTheme}>
      <GlobalStyles
        styles={{
          body: {
            backgroundColor: "#fcfcfc",
            fontFamily: "'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif",
          },
          ".MuiCard-root": {
            boxShadow: "none !important",
            border: "1px solid #dedede",
          },
          ".MuiButton-root": {
            boxShadow: "none !important",
          },
          ".MuiCardContent-root": {
            padding: "24px !important",
          },
          ".MuiCardActions-root": {
            padding: "16px !important",
          },
          ".MuiPaper-root": {
            boxShadow: "none !important",
            border: "1px solid #dedede",
          },
          ".MuiPaper-root-MuiTableContainer-root": {
            boxShadow: "none !important",
          },
          ".MuiAppBar-root": {
            border: 0,
          },
        }}
      />
      <BrowserRouter>
        <Routes>
          <Route path="/oauth_callback" element={<OAuth />} />
          <Route path="/user/add/:instanceId" element={<AddUser />} />
          <Route path="/users/:instanceId" element={<Users />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/" element={<Main />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
