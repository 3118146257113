import React, { Fragment } from "react";
import {
  AppBar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Toolbar,
  Typography,
} from "@mui/material";

const Main = () => {
  return (
    <Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              MinIO Subscription Network
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item xs={3}>
          <Card sx={{ maxWidth: 360 }}>
            <CardContent>
              <Typography variant="h5" component="div">
                Login
              </Typography>
              <Typography variant="body2">
                To get started managing your MinIO Subscription Network account
                you need to identify with your IBM SSO account.
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                Environment: {process.env.NODE_ENV}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                variant={"contained"}
                onClick={() => {
                  if (process.env.NODE_ENV === "development") {
                    window.location.href =
                      "http://localhost:3090/api/v1/authentication/login-start?instance_id=hola";
                    return;
                  }
                  window.location.href = "/api/v1/authentication/login-start";
                }}
              >
                Login With IBM
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Main;
