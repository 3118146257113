import React, { Fragment } from "react";
import { Box, Button, Grid, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ibmButton: {
      "& .MuiButton-endIcon": {
        marginLeft: 30,
      },
    },
  })
);

const Dashboard = () => {
  const classes = useStyles();
  // landing here check for parameters
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  return (
    <Fragment>
      <Box padding={1}>
        <Grid container>
          <Grid item xs={12} textAlign={"right"}>
            <Button
              className={classes.ibmButton}
              style={{
                backgroundColor: "rgb(15, 98, 254)",
                color: "white",
                textTransform: "none",
                fontFamily:
                  "'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif",
                fontSize: 14,
                fontWeight: 400,
                borderRadius: 0,
                paddingLeft: 15,
                paddingTop: 11,
                paddingBottom: 11,
                paddingRight: 15,
              }}
              href={`/api/v1/authentication/login-start?instance_id=${encodeURIComponent(
                params.instance_id
              )}`}
              target={"_blank"}
              endIcon={
                <svg
                  focusable="false"
                  preserveAspectRatio="xMidYMid meet"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  aria-hidden="true"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  className="bx--btn__icon"
                >
                  <path d="M13,14H3c-0.6,0-1-0.4-1-1V3c0-0.6,0.4-1,1-1h5v1H3v10h10V8h1v5C14,13.6,13.6,14,13,14z"></path>
                  <path d="M10 1L10 2 13.3 2 9 6.3 9.7 7 14 2.7 14 6 15 6 15 1z"></path>
                </svg>
              }
            >
              Access Panel
            </Button>
          </Grid>
          <Grid item xs={12}>
            <b>MinIO Subscription Network</b>
            <p>
              The MinIO Subscription Network (Subnet for short) is a simple, yet
              powerful software stack that ensures commercial customers are
              getting the very best out of their MinIO instances. Subnet is
              priced on capacity - just like cloud storage and comes in two
              configurations, Standard and Enterprise.
            </p>

            <p>
                <a href={"https://subnet.min.io"} target={"_blank"}>Subnet</a> combines a commercial license with a unique support model
              that delivers 24/7/365 direct-to-engineer support through a
              MinIO-built portal that blends the best of Slack and Zendesk.
            </p>

            <p>
              The commercial license relieves the obligations associated with
              the GNU AGPL v3 license. This includes the copy-left provision and
              other provisions that the enterprise may find problematic. That
              worry free license, however, is just part of the value proposition
              of <a href={"https://subnet.min.io"} target={"_blank"}>Subnet</a>.
            </p>
            <b>Getting started</b>
            <p>
              To get started access the{" "}
              <a
                href={`/api/v1/authentication/login-start?instance_id=${encodeURIComponent(
                  params.instance_id
                )}`}
                target={"_blank"}
              >
                panel
              </a>{" "}
              to start adding users to your <a href={"https://subnet.min.io"} target={"_blank"}>Subnet</a> account and gain access into
              the system.
            </p>
              <p>
                  After you've been onboarded to Subnet you can go to <a href={"https://subnet.min.io"} target={"_blank"}>https://subnet.min.io</a> to open support requests or ask questions regarding setting up MinIO.
              </p>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default Dashboard;
