import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import { fetchCallback, fetchCount, fetchSessionStatus } from "./systemAPI";

export interface SystemState {
  value: number;
  status: "idle" | "loading" | "failed";
  sessionStatus: "loggedOut" | "loggedIn";
  sessionLoaded: boolean;
  callbackState: string;
  callbackCode: string;
  callbackLoading: boolean;
}

const initialState: SystemState = {
  value: 0,
  status: "idle",
  sessionStatus: "loggedOut",
  sessionLoaded: false,
  callbackState: "",
  callbackCode: "",
  callbackLoading: false,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const incrementAsync = createAsyncThunk(
  "system/fetchCount",
  async (amount: number) => {
    const response = await fetchCount(amount);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);
export const checkSessionAsync = createAsyncThunk(
  "system/fetchSessionStatus",
  async () => {
    const response = await fetchSessionStatus();
    // The value we return becomes the `fulfilled` action payload
    console.log(response.state);
    if (response.state === "invalid") {
      return Promise.reject();
    }
    return response.state;
  }
);
export const callbackAsync = createAsyncThunk(
  "system/callbackAsync",
  async (action: { oauthCode: string; oauthState: string }) => {
    const response = await fetchCallback(action.oauthCode, action.oauthState);
    // The value we return becomes the `fulfilled` action payload
    console.log(response.redirectUrl);
    return response;
  }
);

export const systemSlice = createSlice({
  name: "system",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload;
    },
    loggedIn: (state) => {
      state.sessionStatus = "loggedIn";
    },
    loggedOut: (state) => {
      state.sessionStatus = "loggedOut";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(incrementAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(incrementAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.value += action.payload;
      })
      .addCase(incrementAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(checkSessionAsync.pending, (state, action) => {
        state.sessionLoaded = true;
      })
      .addCase(checkSessionAsync.fulfilled, (state, action) => {
        if (action.payload == "valid") {
          state.sessionStatus = "loggedIn";
        } else {
          state.sessionStatus = "loggedOut";
        }
      })
      .addCase(checkSessionAsync.rejected, (state, action) => {
        state.sessionStatus = "loggedOut";
      })
      .addCase(callbackAsync.pending, (state, action) => {
        state.callbackState = action.meta.arg.oauthState;
        state.callbackCode = action.meta.arg.oauthCode;
        state.callbackLoading = true;
      })
      .addCase(callbackAsync.rejected, (state, action) => {
        state.callbackLoading = false;
      })
      .addCase(callbackAsync.fulfilled, (state, action) => {
        if (action.payload.redirectUrl !== "") {
          console.log(`go to ${action.payload.redirectUrl}`);
          window.location.href = action.payload.redirectUrl!;
        }
        state.callbackLoading = false;
      });
  },
});

export const { increment, decrement, incrementByAmount, loggedIn, loggedOut } =
  systemSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.system.value)`
export const selectCount = (state: RootState) => state.system.value;

export const selectSessionStatus = (state: RootState) =>
  state.system.sessionStatus;

export const selectSessionLoaded = (state: RootState) =>
  state.system.sessionLoaded;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const incrementIfOdd =
  (amount: number): AppThunk =>
  (dispatch, getState) => {
    const currentValue = selectCount(getState());
    if (currentValue % 2 === 1) {
      dispatch(incrementByAmount(amount));
    }
  };

export default systemSlice.reducer;
