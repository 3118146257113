import React, { Fragment, useEffect, useState } from "react";
import {
  Alert,
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { useAppSelector } from "../app/hooks";
import { useNavigate, useParams } from "react-router-dom";

import {
  selectSessionLoaded,
  selectSessionStatus,
} from "../features/system/systemSlice";

interface ServiceUser {
  id: number;
  last_name: string;
  first_name: string;
  email: string;
  role: string;
}

const Users = () => {
  const sessionStatus = useAppSelector(selectSessionStatus);
  const sessionLoaded = useAppSelector(selectSessionLoaded);
  const { instanceId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [rows, setRows] = useState<ServiceUser[]>([]);

  const [selectedUser, setSelectedUser] = useState<ServiceUser | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  // landing here check for parameters
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  if (
    sessionLoaded &&
    sessionStatus === "loggedOut" &&
    params.instance_id &&
    params.instance_id !== ""
  ) {
    window.location.href = `/api/v1/authentication/login-start?instance_id=${encodeURIComponent(
      params.instance_id
    )}`;
  }
  const loadData = () => {
    setError("");
    fetch(`/api/v1/users/?instance_id=${instanceId}`)
      .then((res) => {
        if (res.status >= 300) {
          return Promise.reject(res);
        }
        return res;
      })
      .then()
      .then(
        (res) => {
          res.json().then((response: { items: ServiceUser[] }) => {
            console.log(response);
            if (response.items) {
              setRows(response.items);
            } else {
              setRows([]);
            }
          });
        },
        (err) => {
          err.json().then((body: { code: string; message: string }) => {
            console.log("err", body);
            setError(body.message);
          });
        }
      );
  };

  useEffect(() => {
    if (loading) {
      loadData();
    }
  }, [loading, setLoading, loadData]);

  const confirmDeleteUser = (user: ServiceUser) => {
    setSelectedUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const doDelete = () => {
    setOpen(false);
    if (selectedUser) {
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: null,
      };

      return fetch(
        `/api/v1/users/${selectedUser.id}/?instance_id=${instanceId}`,
        requestOptions
      )
        .then((res) => {
          if (res.status >= 300) {
            return Promise.reject(res);
          }
          return res;
        })
        .then(
          (result) => {
            console.log("done deleting");
            loadData();
          },
          (error) => {
            console.log("error delete", error);
          }
        );
    }
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "fullName",
      headerName: "Full name",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.first_name || ""} ${params.row.last_name || ""}`,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        return (
          <IconButton
            aria-label="delete"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              confirmDeleteUser(params.row as ServiceUser);
            }}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        );
      },
    },
  ];

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Remove User Access from MinIO Subnet
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            A request to remove this user will be issued to remove this user
            from your MinIO Subnet account.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={doDelete}
            variant={"contained"}
            color={"error"}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Box paddingTop={9}>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="fixed">
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                MinIO Subscription Network
              </Typography>
            </Toolbar>
          </AppBar>
        </Box>
        <Grid container spacing={0} style={{ margin: "auto", maxWidth: 1240 }}>
          {error !== "" && (
            <Grid item xs={12}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <h1>Users</h1>
              </Grid>
              <Grid item>
                <Button
                  variant={"contained"}
                  onClick={() => {
                    console.log("add user");
                    navigate(`/user/add/${instanceId}/`);
                  }}
                >
                  Add User
                </Button>
              </Grid>
            </Grid>
            <div style={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default Users;
